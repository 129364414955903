import { useMutation, useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const planApi = {
  getAll(params) {
    return axiosClient.get('/plans', { params });
  },
  getById(id) {
    return axiosClient.get(`/plans/${id}`);
  },
  update(id, data) {
    return axiosClient.patch(`/plans/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/plans', { data: { ids } });
  },
  getPlanQC(id) {
    return axiosClient.get(`/plans/${id}/qc`);
  },
  createPlanQC(id, data) {
    return axiosClient.post(`/plans/${id}/qc`, data);
  },
  confirmPlanQC(id, data) {
    return axiosClient.patch(`/plans/${id}/qc`, data);
  },
  getPlanQCInfo(id) {
    return axiosClient.get(`/plans/${id}/qc-info`);
  },
  updateQuestionAnswers(id, data) {
    return axiosClient.put(`/plans/${id}/question-answers`, data);
  },
  getPlanImages(id, params) {
    return axiosClient.get(`/plans/${id}/images`, { params });
  },
  getPlanAudios(id, params) {
    return axiosClient.get(`/plans/${id}/audios`, { params });
  },
  getNotesInGroups(id, params) {
    return axiosClient.get(`/plans/${id}/notes/groups`, { params });
  },
  getNoteGroups(params) {
    return axiosClient.get('/notes/groups', { params });
  },
  createNote(id, data) {
    return axiosClient.post(`/plans/${id}/notes`, data);
  },
  deleteRepair(planId, id) {
    return axiosClient.delete(`/plans/${planId}/repairs/${id}`);
  },
  confirmRepair(id, repairId, data) {
    return axiosClient.patch(`/plans/${id}/repairs/${repairId}`, data);
  },
  getRepairImagesById(id, params) {
    return axiosClient.get(`/plans/${id}/repairs/images`, { params });
  },
  updateRepairImagesById(id, { imageId, ...data }) {
    return axiosClient.patch(`/plans/${id}/repairs/images/${imageId}`, data);
  },
  deleteNote(planId, id) {
    return axiosClient.delete(`/plans/${planId}/notes/${id}`);
  },

  //
  getPlanOrders(planId, params) {
    return axiosClient.get(`/plans/${planId}/orders`, { params });
  },
  deleteMultiplePlanOrders(planId, orderIds) {
    return axiosClient.delete(`/plans/${planId}/orders`, {
      data: { ids: orderIds },
    });
  },
  getPlanFiles(planId, params) {
    return axiosClient
      .get(`/plans/${planId}/files`, { params })
      .then((response) => response?.data ?? []);
  },
  //
  getDetailOrder(planId, orderId, params) {
    return axiosClient.get(`/plans/${planId}/orders/${orderId}`, {
      params,
    });
  },
};

export const usePlans = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'list', params],
    queryFn: () => planApi.getAll(params),
    ...options,
  });
};

export const usePlan = ({ id, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', id],
    queryFn: () => planApi.getById(id),
    ...options,
  });
};

export const useGetPlanQC = ({ id, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', id, 'qc'],
    queryFn: () => planApi.getPlanQC(id),
    ...options,
  });
};

export const useCreatePlanQC = (id) => {
  return useMutation({
    mutationFn: (data) => planApi.createPlanQC(id, data),
  });
};

export const useConfirmPlanQC = (id) => {
  return useMutation({
    mutationFn: (data) => planApi.confirmPlanQC(id, data),
  });
};

export const usePlanQCInfo = ({ id, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', id, 'qc-info'],
    queryFn: () => planApi.getPlanQCInfo(id),
    ...options,
  });
};

export const useUpdateQuestionAnswers = ({ id } = {}) => {
  return useMutation({
    mutationFn: (data) => planApi.updateQuestionAnswers(id, data),
  });
};

export const usePlanImages = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', id, 'images'],
    queryFn: () => planApi.getPlanImages(id, params),
    ...options,
  });
};

export const usePlanAudios = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', id, 'audios'],
    queryFn: () => planApi.getPlanAudios(id, params),
    ...options,
  });
};

export const useGetNotesInGroups = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', id, 'notes', 'groups', params],
    queryFn: () => planApi.getNotesInGroups(id, params),
    ...options,
  });
};

export const useGetNoteGroups = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'notes', 'groups', params],
    queryFn: () => planApi.getNoteGroups(params),
    ...options,
  });
};

export const useCreateNote = ({ id } = {}) => {
  return useMutation({
    mutationFn: (data) => planApi.createNote(id, data),
  });
};

export const useConfirmRepair = ({ id } = {}) => {
  return useMutation({
    mutationFn: ({ repairId, ...data }) =>
      planApi.confirmRepair(id, repairId, data),
  });
};

export const useGetPlanRepairImages = ({ id, params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', id, 'repairs', 'images', params],
    queryFn: () => planApi.getRepairImagesById(id, params),
    ...options,
  });
};

export const useUpdatePlanRepairImages = ({ id } = {}) => {
  return useMutation({
    mutationFn: (data) => planApi.updateRepairImagesById(id, data),
  });
};

export const useDeleteRepair = ({ planId } = {}) => {
  return useMutation({
    mutationFn: (id) => planApi.deleteRepair(planId, id),
  });
};

export const useDeleteMultiplePlans = () => {
  return useMutation({
    mutationFn: (ids) => planApi.delete(ids),
  });
};

export const useDeleteNote = ({ planId } = {}) => {
  return useMutation({
    mutationFn: (id) => planApi.deleteNote(planId, id),
  });
};

export const useUpdatePlan = ({ planId } = {}) => {
  return useMutation({
    mutationFn: (data) => planApi.update(planId, data),
  });
};

// PLAN ORDERS
export const useGetOrdersInPlan = ({ planId, params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', planId, 'orders', 'list', params],
    queryFn: () => planApi.getPlanOrders(planId, params),
    ...options,
  });
};

export const useDeleteMultiplePlanOrders = ({ planId }) => {
  return useMutation({
    mutationFn: (orderIds) =>
      planApi.deleteMultiplePlanOrders(planId, orderIds),
  });
};

export const useGetPlanFiles = ({ planId, params, options } = {}) => {
  return useQuery({
    queryKey: ['plans', 'details', planId, 'files', 'list', params],
    queryFn: () => planApi.getPlanFiles(planId, params),
    ...options,
  });
};

//

export const useGetDetailOrder = ({ planId, id } = {}) => {
  return useQuery({
    queryKey: ['plans', planId, 'orders', id],
    queryFn: () => planApi.getDetailOrder(planId, id),
  });
};
