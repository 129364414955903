import { useMemo } from 'react';
import _ from 'lodash';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { usePerformanceProgress } from 'api/dashboardApi';
import PerformanceProgressFilterSearch from '../PerformanceProgressFilterSearch/PerformanceProgressFilterSearch';
import PerformanceProgressTable from '../PerformanceProgressTable/PerformanceProgressTable';
import moment from 'moment';
import { FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER } from 'constants/common';

export default function PerformanceProgress() {
  const [search, setSearch] = useCustomSearchParams();

  const convertSearch = useMemo(() => {
    return {
      ...search,
      date: moment(search.date, FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER).isValid()
        ? search.date
        : moment(new Date()).format(FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER),
    };
  }, [search]);

  const { data, isLoading, isFetching } = usePerformanceProgress({
    params: convertSearch,
    options: {
      select: (response) => {
        return _.reduce(
          response,
          (arr, currentItem) => {
            const { children, ...restCurrentItem } = currentItem;

            arr.push({ ...restCurrentItem, isBold: true }, ...children);

            return arr;
          },
          [],
        );
      },
    },
  });

  console.log({ convertSearch });

  return (
    <>
      <PerformanceProgressFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={convertSearch}
      />

      <PerformanceProgressTable
        dataSource={data}
        isLoading={isLoading || isFetching}
      />
    </>
  );
}
