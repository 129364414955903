import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { DEFAULT_PAGINATION } from 'constants/common';
import { FilterForm } from 'components/form';
import {
  StyledSpace,
  StyledFormItem,
  StyledInputSearch,
} from 'styles/overrides';
import {
  StoreStatusSelector,
  QCStatusSelector,
  FromToDateSelector,
  ProvinceSelector,
  UserSelector,
  DistrictSelector,
  RepairStatusSelector,
} from 'components/selectors';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';

export default function ExportFilterSearch({ onSubmit, initialValues }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
        page: DEFAULT_PAGINATION.PAGE,
      }),
    );
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: null,
      });
    }
  };

  const handleClear = (name) => {
    if (name === 'province_id') {
      form.setFieldsValue({
        district_id: null,
      });
    }
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      formInstance={form}
      onValuesChange={handleValuesChange}
    >
      <StyledSpace size={16}>
        <StyledFormItem noStyle shouldUpdate>
          {(form) => (
            <StyledFormItem name="search">
              <StyledInputSearch
                style={{ width: 'auto' }}
                $customType="filter"
                placeholder={intl.formatMessage({
                  id: 'common.placeholder.search',
                })}
                onSearch={form.submit}
              />
            </StyledFormItem>
          )}
        </StyledFormItem>

        <StyledFormItem name="store_status">
          <StoreStatusSelector
            placeholder={intl.formatMessage({
              id: 'common.placeholder.status',
            })}
            $customType="filter"
            allowClear
          />
        </StyledFormItem>

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
          <StyledFormItem name="qc">
            <QCStatusSelector
              placeholder={intl.formatMessage({
                id: 'common.placeholder.qcStatus',
              })}
              $customType="filter"
              allowClear
            />
          </StyledFormItem>
        </Permission>

        <StyledFormItem name="fromToDate">
          <FromToDateSelector allowClear format="DD/MM/YYYY" />
        </StyledFormItem>

        <StyledFormItem name="user_id">
          <UserSelector
            $customType="filter"
            allowClear
            params={{ role: ROLE.USER, page_size: 1000 }}
          />
        </StyledFormItem>

        <StyledFormItem name="province_id">
          <ProvinceSelector
            $customType="filter"
            allowClear
            onClear={() => handleClear('province_id')}
          />
        </StyledFormItem>

        <StyledFormItem
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.province_id !== currentValues.province_id
          }
        >
          {({ getFieldValue }) => {
            const provinceId = getFieldValue('province_id');

            return (
              <StyledFormItem name="district_id">
                <DistrictSelector
                  provinceId={provinceId}
                  $customType="filter"
                  allowClear
                  onClear={() => handleClear('district_id')}
                />
              </StyledFormItem>
            );
          }}
        </StyledFormItem>

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
          <StyledFormItem name="is_repair">
            <RepairStatusSelector allowClear />
          </StyledFormItem>
        </Permission>

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
          <StyledFormItem name="qc_id">
            <UserSelector
              $customType="filter"
              allowClear
              params={{ role: ROLE.QC, page_size: 1000 }}
              placeholder={intl.formatMessage({
                id: 'common.placeholder.qcId',
              })}
            />
          </StyledFormItem>
        </Permission>
      </StyledSpace>
    </FilterForm>
  );
}
