import { useState, useCallback, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { usePlan } from 'api/planApi';
import {
  PlanSurvey,
  PlanInfo,
  PlanStore,
  PlanQC,
  PlanQCConfirm,
  PlanNote,
  PlanFix,
  PlanOrders,
  PlanImages,
} from './components';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import _ from 'lodash';
import { useGetPlanFiles } from 'api/planApi';

export default function ViewPlan() {
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);

  const { data, isLoading, refetch, isFetching } = usePlan({
    id,
    options: {
      select: (response) => response?.data ?? null,
    },
  });
  // const {
  //   data: planFiles,
  //   isSuccess: isFilesSuccess,
  //   isLoading: isFilesLoading,
  //   refetch: filesRefetch,
  // } = useGetPlanFiles({ id });

  useEffect(() => {
    if (data?.question?.length > 0) {
      setQuestions(data.question);
    }
  }, [data]);

  const handleShowHideQuestions = useCallback(
    ({ showQuestions, hideQuestions }) => {
      setQuestions((prevQuestions) => {
        const clonedPrevQuestions = _.cloneDeep(prevQuestions);

        _.forEach(showQuestions, (item) => {
          console.log(item, `${item.element_path}.is_show`);
          _.set(clonedPrevQuestions, `${item.element_path}.is_show`, 1);
        });

        _.forEach(hideQuestions, (item) => {
          _.set(clonedPrevQuestions, `${item.element_path}.is_show`, 0);
        });

        return clonedPrevQuestions;
      });
    },
    [],
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  // console.log(planFiles);
  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <PlanInfo
          version={data.version}
          deviceModel={data.device_model}
          user={data.user}
          isDone={data.status}
          qcStatus={data.qc_status}
          storeStatus={data.store_status}
          checkin={data.checkin}
          checkout={data.checkout}
          checkinNote={data.checkin_note}
          planId={id}
          isLoading={isFetching}
        />
      </Col>

      <Col span={12}>
        <PlanStore store={data.store} />
      </Col>

      {/* PLAN ORDERS */}
      <Col span={24}>
        <PlanOrders planId={id} />
      </Col>

      <Col span={24}>
        <PlanImages planId={id} />
      </Col>

      {!!data.status && (
        <>
          <Col span={24}>
            <PlanSurvey
              questions={questions}
              questionAnswerData={data.question_answer_data}
              planId={id}
              onShowHideQuestions={handleShowHideQuestions}
            />
          </Col>

          <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
            {!!data.is_repair && (
              <Col span={24}>
                <PlanFix planId={id} />
              </Col>
            )}
          </Permission>

          <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
            <Col span={24}>
              <PlanQC planId={id} />
            </Col>
          </Permission>

          <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
            <Col span={24}>
              <PlanNote planId={id} />
            </Col>
          </Permission>

          <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
            <Col span={24}>
              <PlanQCConfirm planId={id} onSuccess={refetch} />
            </Col>
          </Permission>
        </>
      )}
    </Row>
  );
}
