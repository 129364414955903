import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Row, Col } from 'antd';

import { StyledButton, StyledSpace, StyledInputNumber } from 'styles/overrides';
import { MinusCircleOutlined } from '@ant-design/icons';
import { StyledFormList, StyledFormItem } from 'styles/overrides';
import ProductsSelector from 'components/selectors/ProductsSelector/ProductsSelector';
import _ from 'lodash';
import { formatInputNumber, parseInputNumber } from 'utils/formatUtils';

function formatInitialValue(initArray) {
  return _.map(initArray, (item) => {
    const updatedObject = {
      id: item.product_id,
      quantity: item.quantity,
      price: +item.price,
    };
    return updatedObject;
  });
}

function DetailOrderItem({ formName, initialValues = [], selectorType }) {
  const intl = useIntl();
  const formatedInit = formatInitialValue(initialValues);
  console.log(formatedInit);
  return (
    <StyledFormList name={formName} initialValue={formatedInit}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Row key={key} justify="space-between">
              <Col span={8}>
                <StyledFormItem
                  {...restField}
                  name={[name, 'id']}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'views.plans.order.error.invalidName',
                      }),
                    },
                  ]}
                >
                  <ProductsSelector type={selectorType} />
                </StyledFormItem>
              </Col>

              <Col span={6}>
                <StyledFormItem
                  {...restField}
                  name={[name, 'quantity']}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'views.plans.order.error.invalidQuantity',
                      }),
                    },
                  ]}
                >
                  <StyledInputNumber
                    placeholder={intl.formatMessage({
                      id: 'views.plans.order.placeholder.quantity',
                    })}
                    min={0}
                    controls={false}
                    formatter={formatInputNumber}
                    parser={parseInputNumber}
                    addonAfter={
                      <p>cái</p>
                    }
                  />
                </StyledFormItem>
              </Col>

              <Col span={8}>
                {formName === 'gifts' ? null : (
                  <StyledFormItem
                    {...restField}
                    name={[name, 'price']}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'views.plans.order.error.invalidPrice',
                        }),
                      },
                    ]}
                  >
                    <StyledInputNumber
                      placeholder={intl.formatMessage({
                        id: 'views.plans.order.placeholder.price',
                      })}
                      min={0}
                      controls={false}
                      formatter={formatInputNumber}
                      parser={parseInputNumber}
                      addonAfter={
                        <p>VND</p>
                      }
                    />
                  </StyledFormItem>
                )}
              </Col>

              <Col span={1}>
                <StyledFormItem>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </StyledFormItem>
              </Col>
            </Row>
          ))}
          <StyledFormItem>
            <StyledButton type="dashed" onClick={() => add()} block>
              {intl.formatMessage({
                id: 'common.btn.addProduct',
              })}
            </StyledButton>
          </StyledFormItem>
        </>
      )}
    </StyledFormList>
  );
}

export default DetailOrderItem;
