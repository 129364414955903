import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Spin, Modal } from 'antd';
import { formatTime } from 'utils/timeUtils';
import { Table } from 'components/table';
import { Notification } from 'components/Notification';
import {
  StyledCard,
  StyledButton,
  StyledSpace,
  StyledDrawer,
} from 'styles/overrides';
import { useGetOrdersInPlan, useDeleteMultiplePlanOrders } from 'api/planApi';
import PlanOrderForm from '../PlanOrderForm/PlanOrderForm';

export default function PlanOrders({ planId }) {
  const intl = useIntl();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const isDisabledDelete = selectedRowKeys.length <= 0;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectItem] = useState(null);

  const deleteMultiplePlanOrdersMutation = useDeleteMultiplePlanOrders({
    planId,
  });
  const {
    data: orders,
    isLoading,
    isFetching,
    refetch,
  } = useGetOrdersInPlan({ planId });

  const handleSelectItemToEdit = (item) => {
    setSelectItem(item);
    setIsOpen(true);
  };

  const handleClose = () => {
    setSelectItem(null);
    setIsOpen(false);
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'views.plans.planOrders.table.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'views.plans.planOrders.table.phone' }),
      dataIndex: 'phone',
    },
    {
      title: intl.formatMessage({ id: 'views.plans.planOrders.table.address' }),
      dataIndex: 'address',
    },
    {
      title: intl.formatMessage({ id: 'views.plans.planOrders.table.note' }),
      dataIndex: 'note',
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
    {
      render: (record) => (
        <StyledButton
          type="primary"
          onClick={() => handleSelectItemToEdit(record)}
        >
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>
      ),
    },
  ];

  const handleDeleteMultipleOrders = () => {
    if (isDisabledDelete) {
      return;
    }

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMultiplePlanOrdersMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );

            setSelectedRowKeys([]);
            refetch();
          },
          onError(error) {
            Notification(
              'error',
              error.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Spin
        spinning={
          deleteMultiplePlanOrdersMutation.isLoading || isLoading || isFetching
        }
      >
        <StyledCard
          bodyStyle={{ padding: 0 }}
          title={intl.formatMessage({ id: 'views.plans.planOrders.title' })}
          extra={
            <StyledSpace>
              <StyledButton
                type="danger"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {intl.formatMessage({ id: 'common.btn.addNew' })}
              </StyledButton>
              <StyledButton
                type="danger"
                onClick={handleDeleteMultipleOrders}
                disabled={isDisabledDelete}
              >
                {intl.formatMessage({ id: 'common.btn.delete' })}
              </StyledButton>
            </StyledSpace>
          }
          size="small"
        >
          <Table
            rowKey="id"
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys) => {
                setSelectedRowKeys(newSelectedRowKeys);
              },
              preserveSelectedRowKeys: true,
            }}
            columns={columns}
            dataSource={orders?.data || []}
            loading={isLoading}
            pagination={false}
          />
        </StyledCard>
      </Spin>

      <StyledDrawer open={isOpen} onClose={handleClose} size="large">
        <PlanOrderForm initialValues={selectedItem} />
      </StyledDrawer>
    </>
  );
}
