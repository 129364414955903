import React from 'react';
import { useIntl } from 'react-intl';
import { Alert, Spin } from 'antd';
import {
  useShowPermissionExport,
  useRequestPermissionExport,
} from 'api/exportApi';
import { StyledSpace, StyledCard, StyledButton } from 'styles/overrides';
import { Notification } from 'components/Notification';

export default function ExportItem({
  exportName,
  mutation,
  isCheckPermission = true,
}) {
  const intl = useIntl();
  const { data, isLoading } = useShowPermissionExport({
    params: { export: exportName },
    options: {
      enabled: isCheckPermission,
    },
  });
  const requestPermissionExportMutation = useRequestPermissionExport();

  const handleRequestPermission = () => {
    requestPermissionExportMutation.mutate(
      { export: exportName },
      {
        onSuccess() {
          Notification(
            'success',
            intl.formatMessage({
              id: 'message.requestPermissionExport',
            }),
          );
        },
        onError(error) {
          Notification(
            'error',
            error.message ||
              intl.formatMessage({
                id: 'message.commonError',
              }),
          );
        },
      },
    );
  };

  const handleExport = () => {
    if (!mutation) return;

    mutation.mutate(
      {},
      {
        onSuccess(response) {
          if (response?.path) {
            window.location.href = response.path;
          }
        },
        onError(error) {
          Notification(
            'error',
            error.message ||
              intl.formatMessage({
                id: 'message.commonError',
              }),
          );
        },
      },
    );
  };

  if (isLoading) return null;

  return (
    <Spin
      spinning={
        requestPermissionExportMutation.isLoading || mutation?.isLoading
      }
    >
      <StyledCard
        title={intl.formatMessage({ id: `views.exports.title.${exportName}` })}
      >
        {!!data && isCheckPermission && (
          <StyledSpace direction="vertical" size={16}>
            {!!data.allow_export ? (
              <StyledButton type="primary" onClick={handleExport}>
                {intl.formatMessage({ id: 'common.btn.export' })}
              </StyledButton>
            ) : (
              <StyledButton type="danger" onClick={handleRequestPermission}>
                {intl.formatMessage({
                  id: 'common.btn.requestPermissionExport',
                })}
              </StyledButton>
            )}

            <Alert
              type={!!data.allow_export ? 'success' : 'error'}
              message={data.message}
            />
          </StyledSpace>
        )}

        {!isCheckPermission && (
          <StyledButton type="primary" onClick={handleExport}>
            {intl.formatMessage({ id: 'common.btn.export' })}
          </StyledButton>
        )}
      </StyledCard>
    </Spin>
  );
}
