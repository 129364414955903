import { useIntl } from 'react-intl';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { useProducts } from 'api/productApi';
import { normalizeOptions, filterOptions } from 'utils/formatUtils';

export default function ProductsSelector({
  type,
  onChange,
  value,
  ...restProps
}) {
  const intl = useIntl();

  const { data = [], isLoading } = useProducts({
    params: { type },
    options: {
      select: (response) => normalizeOptions(response.data, 'name', 'id'),
    },
  });
  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      placeholder={intl.formatMessage({ id: 'common.placeholder.product' })}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
