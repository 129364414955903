/* eslint-disable no-template-curly-in-string */
export const PATH_NAME = {
  ROOT: '/',
  LOGIN: '/login',
  SETTING: '/setting',
  HISTORY: '/history',
  PENDING: '/pending',
  TRANSACTION: '/transactions',
  RULE: '/rules',
  SPORT: '/sports',
};

export const ROLE = {
  ADMIN: 'Admin',
  USER: 'User',
};

export const STORAGE_KEY = {
  TOKEN: 'token',
  OTP_TOKEN: 'otp_token',
};

export const LOCALE = {
  VIETNAMESE: 'vi',
  ENGLISH: 'en',
};

export const LOCALES = [
  {
    id: LOCALE.VIETNAMESE,
    name: `locale.${LOCALE.VIETNAMESE}`,
  },
  {
    id: LOCALE.ENGLISH,
    name: `locale.${LOCALE.ENGLISH}`,
  },
];

export const validateMessages = {
  [LOCALE.ENGLISH]: {
    required: '${label} is required',
  },
  [LOCALE.VIETNAMESE]: {
    required: '${label} bắt buộc',
  },
};

export const DEFAULT_PAGINATION = {
  PAGE: 1,
  LIMIT: 10,
};

export const TEXT_DEFAULT = {
  NA: 'N/A',
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const FROM_TO_DATE_SUBMIT_FORMAT = 'YYYY/MM/DD';
export const MONTH_FORMAT = 'MM/YYYY';

export const FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER = 'YYYY-MM-DD';
export const MONTH_FORMAT_TO_SERVER = 'YYYY-MM';

export const CHART_FILTER = ['year', 'month', 'week'];

//

export const PRODUCT_TYPE = {
  SELL: 'sell',
  SAMPLE: 'sample',
  GIFT: 'gift',
};

export const FILE_GROUP_TYPE = {
  PLAN: 'plan',
};
