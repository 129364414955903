import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Grid, Drawer } from 'antd';
import { ROLE } from 'constants/roles';
import { PATH_NAME } from 'constants/routes';
import {
  StyledLayout,
  StyledLayoutContentWrapper,
  StyledLayoutContent,
} from 'styles/overrides';
import {
  PeopleIcon,
  HomeIcon,
  LayoutIcon,
  DownloadIcon,
  PieChartIcon,
} from 'assets/icons';
import { getCurrentUser } from 'store/slices/authSlice';
import { Sider } from './components/Sider';
import { Header } from './components/Header';

const MENU_ITEMS_BY_ROLE = {
  [ROLE.ADMIN]: [
    {
      url: PATH_NAME.USERS,
      key: 'users',
      icon: <PeopleIcon />,
    },
    {
      url: PATH_NAME.STORES,
      key: 'stores',
      icon: <HomeIcon />,
    },
    {
      url: PATH_NAME.PLANS,
      key: 'plans',
      icon: <LayoutIcon />,
    },
    {
      url: PATH_NAME.PRODUCTS,
      key: 'products',
      icon: <LayoutIcon />,
    },
    {
      key: 'dashboard',
      icon: <PieChartIcon />,
      children: [
        {
          key: 'performance-progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PERFORMANCE_PROGRESS}`,
        },
        {
          key: 'map',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_MAP}`,
        },
        {
          key: 'result',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_RESULT_CHART}`,
        },
        {
          key: 'progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PROGRESS}`,
        },
      ],
    },
  ],
  [ROLE.PL]: [
    {
      url: PATH_NAME.USERS,
      key: 'users',
      icon: <PeopleIcon />,
    },
    {
      url: PATH_NAME.STORES,
      key: 'stores',
      icon: <HomeIcon />,
    },
    {
      url: PATH_NAME.PLANS,
      key: 'plans',
      icon: <LayoutIcon />,
    },
    {
      url: PATH_NAME.EXPORTS,
      key: 'exports',
      icon: <DownloadIcon />,
    },
    {
      key: 'dashboard',
      icon: <PieChartIcon />,
      children: [
        {
          key: 'performance-progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PERFORMANCE_PROGRESS}`,
        },
        {
          key: 'map',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_MAP}`,
        },
        {
          key: 'result',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_RESULT_CHART}`,
        },
        {
          key: 'progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PROGRESS}`,
        },
      ],
    },
  ],
  [ROLE.SUP]: [
    {
      url: PATH_NAME.STORES,
      key: 'stores',
      icon: <HomeIcon />,
    },
    {
      url: PATH_NAME.PLANS,
      key: 'plans',
      icon: <LayoutIcon />,
    },
    {
      key: 'dashboard',
      icon: <PieChartIcon />,
      children: [
        {
          key: 'performance-progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PERFORMANCE_PROGRESS}`,
        },
        {
          key: 'map',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_MAP}`,
        },
        {
          key: 'result',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_RESULT_CHART}`,
        },
        {
          key: 'progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PROGRESS}`,
        },
      ],
    },
  ],
  [ROLE.QC]: [
    {
      url: PATH_NAME.STORES,
      key: 'stores',
      icon: <HomeIcon />,
    },
    {
      url: PATH_NAME.PLANS,
      key: 'plans',
      icon: <LayoutIcon />,
    },
    {
      key: 'dashboard',
      icon: <PieChartIcon />,
      children: [
        {
          key: 'performance-progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PERFORMANCE_PROGRESS}`,
        },
        {
          key: 'map',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_MAP}`,
        },
        {
          key: 'result',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_RESULT_CHART}`,
        },
        {
          key: 'progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PROGRESS}`,
        },
      ],
    },
  ],
  [ROLE.CUSTOMER]: [
    {
      url: PATH_NAME.STORES,
      key: 'stores',
      icon: <HomeIcon />,
    },
    {
      url: PATH_NAME.PLANS,
      key: 'plans',
      icon: <LayoutIcon />,
    },
    {
      key: 'dashboard',
      icon: <PieChartIcon />,
      children: [
        {
          key: 'map',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_MAP}`,
        },
        {
          key: 'result',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_RESULT_CHART}`,
        },
        {
          key: 'progress',
          url: `${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_PROGRESS}`,
        },
      ],
    },
  ],
};

export default function DashboardLayout() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const screens = Grid.useBreakpoint();

  const filteredMenuItems = useMemo(
    () => MENU_ITEMS_BY_ROLE[currentUser?.role?.name] ?? [],
    [currentUser],
  );

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <>
      <StyledLayout>
        {screens.lg && (
          <Sider collapsed={isCollapsed} items={filteredMenuItems} />
        )}

        <StyledLayoutContentWrapper collapsed={isCollapsed.toString()}>
          <Header onCollapse={handleToggleCollapse} />
          <StyledLayoutContent>
            <Outlet />
          </StyledLayoutContent>
        </StyledLayoutContentWrapper>
      </StyledLayout>

      {!screens.lg && (
        <Drawer
          open={isCollapsed}
          closable={false}
          onClose={() => setIsCollapsed(false)}
          bodyStyle={{ padding: 0 }}
          contentWrapperStyle={{ width: 'auto' }}
          placement="left"
        >
          <Sider items={filteredMenuItems} />
        </Drawer>
      )}
    </>
  );
}
