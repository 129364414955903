import { useUserRoles } from 'api/userApi';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { filterOptions } from 'utils/formatUtils';

export default function RoleSelector({ onChange, value, ...restProps }) {
  const { data = [], isLoading } = useUserRoles({
    options: {
      select: (response) =>
        _.map(response, ({ name }) => ({
          label: name,
          value: name,
        })) ?? [],
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
