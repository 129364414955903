import { ContentWrapper } from 'components/layout';
import { ResultChart } from './components/result-chart';

export default function ViewResultChart() {
  return (
    <ContentWrapper title="Biểu đồ kết quả">
      <ResultChart />
    </ContentWrapper>
  );
}
