import { ContentWrapper } from 'components/layout';
import { PerformanceProgress } from './components/performance-progress';

export default function ViewPerformanceProgress() {
  return (
    <ContentWrapper title="Tiến độ thực hiện">
      <PerformanceProgress />
    </ContentWrapper>
  );
}
