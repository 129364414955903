import { Select } from 'antd';
import { StyledSelect } from 'styles/overrides';
import { useGetAllTypes } from 'api/productApi';
import { useIntl } from 'react-intl';

export const TypeSelector = ({ onChange, value, ...restProps }) => {
  const intl = useIntl();
  const { data, isLoading } = useGetAllTypes();
  return (
    <StyledSelect
      placeholder={intl.formatMessage({
        id: 'common.placeholder.type',
      })}
      {...restProps}
      loading={isLoading}
      onChange={onChange}
      value={value}
    >
      {data?.data.length > 0 &&
        data?.data.map(({ id, code ,name }) => (
          <Select.Option key={code} value={code}>
            {name}
          </Select.Option>
        ))}
    </StyledSelect>
  );
};
