import { useIntl } from 'react-intl';
import { Form, DatePicker } from 'antd';
import { MONTH_FORMAT } from 'constants/common';
import { FilterForm } from 'components/form';
import { StyledSpace, StyledFormItem } from 'styles/overrides';
import {
  DistrictSelector,
  FromToDateSelector,
  ProvinceSelector,
} from 'components/selectors';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';

export default function ProgressChartFilterSearch({ onSubmit, initialValues }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
      }),
    );
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (changedValues['district_id']) {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  const handleClear = (name) => {
    if (name === 'province_id') {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (name === 'district_id') {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      formInstance={form}
      onValuesChange={handleValuesChange}
    >
      <StyledSpace size={16}>
        <StyledFormItem name="month">
          <DatePicker
            picker="month"
            format={MONTH_FORMAT}
            placeholder={intl.formatMessage({ id: 'common.placeholder.month' })}
            allowClear
          />
        </StyledFormItem>

        <StyledFormItem name="fromToDate">
          <FromToDateSelector allowClear />
        </StyledFormItem>

        <StyledFormItem name="province_id">
          <ProvinceSelector
            $customType="filter"
            allowClear
            onClear={() => handleClear('province_id')}
          />
        </StyledFormItem>

        <StyledFormItem
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.province_id !== currentValues.province_id
          }
        >
          {({ getFieldValue }) => {
            const provinceId = getFieldValue('province_id');

            return (
              <StyledFormItem name="district_id">
                <DistrictSelector
                  provinceId={provinceId}
                  allowClear
                  onClear={() => handleClear('district_id')}
                  $customType="filter"
                />
              </StyledFormItem>
            );
          }}
        </StyledFormItem>
      </StyledSpace>
    </FilterForm>
  );
}
