import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import flatten from 'flat';
import moment from 'moment';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';
import 'moment/locale/vi';

import THEME from 'config/themes';
import AppLocale from 'config/translations';
import { validateMessages } from 'constants/common';
import { ROLE } from 'constants/roles';
import { PATH_NAME } from 'constants/routes';
import GlobalStyle from 'styles/globalStyle';
import { PrivateRoute } from 'components';
import DashboardLayout from './layout/DashboardLayout';
import AuthLayout from './layout/AuthLayout';
import Login from './views/auth/Login';
import Users from './views/users/Users';
import { NotFound } from './components/layout/NotFound';
import { Permission } from 'components/permission/Permission';
import Stores from './views/stores/Stores';
import CreateStore from 'views/stores/CreateStore';
import ViewStore from './views/stores/ViewStore';
import Plans from './views/plans/Plans';
import ViewPlan from './views/plans/ViewPlan';
import { DetailOrder } from 'views/plans/components/PlanOrders/components';
import Exports from './views/exports/Exports';
import RequestPermissionExport from './views/exports/RequestPermissionExport';
import ViewProgressChart from './views/dashboard/ViewProgressChart';
import ViewPerformanceProgress from './views/dashboard/ViewPerformanceProgress';
import ViewMap from './views/dashboard/ViewMap';
import ViewResultChart from './views/dashboard/ViewResultChart';
import Products from 'views/products/Products';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const locale = useSelector((state) => state.app.currentLocale);

  moment.locale(locale);

  return (
    <ConfigProvider
      locale={AppLocale[locale].antd}
      form={{ validateMessages: validateMessages[locale], requiredMark: false }}
      // componentSize="small"
    >
      <IntlProvider
        locale={locale}
        messages={flatten(AppLocale[locale].messages)}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={THEME}>
            <GlobalStyle />

            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path={PATH_NAME.ROOT} element={<DashboardLayout />}>
                  <Route path={PATH_NAME.USERS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN, ROLE.PL]}
                          noAccess={<NotFound />}
                        >
                          <Users />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.STORES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.SUP,
                            ROLE.QC,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <Stores />
                        </Permission>
                      }
                    />
                    <Route
                      index
                      path="create"
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.SUP,
                            ROLE.QC,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <CreateStore />
                        </Permission>
                      }
                    />
                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.SUP,
                            ROLE.QC,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <ViewStore />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.PLANS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.SUP,
                            ROLE.QC,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <Plans />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.SUP,
                            ROLE.QC,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <ViewPlan />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id/:id"
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.SUP,
                            ROLE.QC,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <DetailOrder />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.PRODUCTS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.SUP,
                            ROLE.QC,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <Products />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.SUP,
                            ROLE.QC,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <ViewPlan />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.EXPORTS}>
                    <Route
                      index
                      element={
                        <Permission roles={[ROLE.PL]} noAccess={<NotFound />}>
                          <Exports />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.DASHBOARD}>
                    <Route
                      path={PATH_NAME.DASHBOARD_PERFORMANCE_PROGRESS}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}
                          noAccess={<NotFound />}
                        >
                          <ViewPerformanceProgress />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME.DASHBOARD_MAP}
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.QC,
                            ROLE.SUP,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <ViewMap />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME.DASHBOARD_RESULT_CHART}
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.QC,
                            ROLE.SUP,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <ViewResultChart />
                        </Permission>
                      }
                    />

                    <Route
                      path={PATH_NAME.DASHBOARD_PROGRESS}
                      element={
                        <Permission
                          roles={[
                            ROLE.ADMIN,
                            ROLE.PL,
                            ROLE.QC,
                            ROLE.SUP,
                            ROLE.CUSTOMER,
                          ]}
                          noAccess={<NotFound />}
                        >
                          <ViewProgressChart />
                        </Permission>
                      }
                    />
                  </Route>

                  {/* END */}

                  {/* <Route
                    index
                    element={
                      <Permission
                        roles={[ROLE.VIEWER]}
                        noAccess={<Navigate to={PATH_NAME.DASHBOARD} />}
                      >
                        <Navigate to={PATH_NAME.ORDERS} />
                      </Permission>
                    }
                  />

                  <Route path={PATH_NAME.DASHBOARD}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Dashboard />
                        </Permission>
                      }
                    />

                    <Route
                      path="detail"
                      element={
                        <Permission
                          roles={[ROLE.SUPPER_ADMIN, ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <ViewDashboard />
                        </Permission>
                      }
                    />
                  </Route> */}

                  <Route path="*" element={<NotFound />} />
                </Route>
              </Route>

              <Route element={<AuthLayout />}>
                <Route
                  path={PATH_NAME.REQUEST_PERMISSION_EXPORT}
                  element={<RequestPermissionExport />}
                />

                <Route path={PATH_NAME.LOGIN} element={<Login />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
