import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Image, Row, Col, Typography } from 'antd';
import {
  RotateRightOutlined,
  RotateLeftOutlined,
  SaveOutlined,
  PaperClipOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { StyledButton } from 'styles/overrides';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { icons } from '../../../config/lib/antd';
import { useState } from 'react';

export default function PreviewImages({
  images = [],
  canDelete = false,
  canSetAvatar = false,
  onDeleteImage = () => {},
  onRotateImage = () => {},
  onSetAvatar = () => {},
}) {
  const intl = useIntl();
  const [rotate, setRotate] = useState(0);
  const [visible, setVisible] = useState(false);

  const handleRotateRight = () => {
    setRotate((prev) => prev + 90);
  };

  const handleRotateLeft = () => {
    setRotate((prev) => prev - 90);
  };

  return (
    <>
      {images.length > 0 ? (
        <Image.PreviewGroup
          preview={{
            countRender: (current) => {
              return (
                <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
                  <div className="ant-image-preview-operations-operation">
                    <SaveOutlined
                      className="ant-image-preview-operations-icon"
                      onClick={() => {
                        onRotateImage(images[current - 1].id, rotate);
                        setVisible(false);
                      }}
                    />
                  </div>
                </Permission>
              );
            },
            visible,
            onVisibleChange: (visible) => {
              setRotate(0);
              setVisible(visible);
            },
            destroyOnClose: true,
          }}
          icons={{
            ...icons,
            rotateRight: <RotateRightOutlined onClick={handleRotateRight} />,
            rotateLeft: <RotateLeftOutlined onClick={handleRotateLeft} />,
          }}
        >
          <Row gutter={[24, 24]}>
            {_.map(images, ({ id, path, is_repair }) => (
              <Col span={8} key={id}>
                <div
                  style={{
                    position: 'relative',
                    border: !!is_repair ? '2px solid yellow' : 'none',
                  }}
                >
                  <Image
                    src={path}
                    width="100%"
                    onClick={() => setVisible(true)}
                    height={250}
                    style={{ objectFit: 'cover' }}
                  />

                  {canDelete && (
                    <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
                      <StyledButton
                        type="danger"
                        icon={<DeleteOutlined />}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={() => onDeleteImage(id)}
                      />
                    </Permission>
                  )}

                  {canSetAvatar && (
                    <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
                      <StyledButton
                        type="primary"
                        icon={<PaperClipOutlined />}
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        onClick={() => onSetAvatar(id)}
                        title="Set avatar"
                      />
                    </Permission>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Image.PreviewGroup>
      ) : (
        <Typography.Text>
          {intl.formatMessage({ id: 'message.noImage' })}
        </Typography.Text>
      )}
    </>
  );
}
