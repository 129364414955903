import { useMutation, useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const fileApi = {
  getFileGroups(params) {
    return axiosClient
      .get('/file/groups', { params })
      .then((response) => response?.data ?? []);
  },
  delete({ id, params }) {
    return axiosClient.delete(`/files/${id}`, { params });
  },
  create(data) {
    return axiosClient.post('/files', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  rotate({ id, rotate }) {
    return axiosClient.patch(`/files/${id}/rotate`, {}, { params: { rotate } });
  },
};

export const useFileGroups = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['file-groups', 'list', params],
    queryFn: () => fileApi.getFileGroups(params),
    ...options,
  });
};

export const useDeleteFile = () => {
  return useMutation(fileApi.delete);
};

export const useCreateFile = () => {
  return useMutation(fileApi.create);
};

export const useRotateFile = () => {
  return useMutation(fileApi.rotate);
};
