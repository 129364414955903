import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore, useCreateStore, useSetStoreAvatar } from 'api/storeApi';
import StoreForm from './components/StoreForm';
import { Spin } from 'antd';
import { StyledSpace } from 'styles/overrides';
import { Notification } from 'components/Notification';
import { redirect } from "react-router-dom";


export default function CreateStore() {
  const intl = useIntl()
  const { id } = useParams();
  const createStoreMutation = useCreateStore();
  const navigate = useNavigate()

  const handleCreateStore = (values) => {
    // console.log(values)
    createStoreMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: 'message.createSuccess',
          }),
        );
        navigate("/stores")
      },
      
      onError(error) {
        Notification(
          'error',
          error.message ||
            intl.formatMessage({
              id: 'message.commonError',
            }),
        );
      },
    });
  };
  return (
    <Spin
      spinning={
        createStoreMutation.isLoading
      }
    >
      <StyledSpace direction="vertical" size={24}>
        <StoreForm onSubmit={handleCreateStore} />
      </StyledSpace>
    </Spin>
  );
}
