import { ContentWrapper } from 'components/layout';
import { ProgressChart } from './components/progress-chart';

export default function ViewProgressChart() {
  return (
    <ContentWrapper title="Biểu đồ tiến độ">
      <ProgressChart />
    </ContentWrapper>
  );
}
