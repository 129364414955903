import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import AddEditUserForm from '../AddEditUserForm/AddEditUserForm';
import { Modal } from 'components/modal/Modal';
import { useCreateUser, useUpdateUser } from 'api/userApi';
import { Notification } from 'components/Notification';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import _ from 'lodash';

const formattedInitialValues = (data) => {
  if (!data) {
    return null;
  }

  const {
    email,
    phone,
    full_name,
    user_name,
    password_raw,
    role,
    sup,
    is_active,
  } = data;

  return {
    user_name,
    full_name,
    email,
    phone,
    password: password_raw,
    role: role.name,
    sup_id: sup?.id?.toString(),
    is_active: !!is_active,
  };
};

const formatSubmitData = (data) => {
  const { is_active, sup_id, ...restData } = data;
  const submitData = {
    ...restData,
    sup_id: sup_id ?? null,
  };

  if (!_.isNil(is_active)) {
    submitData.is_active = +is_active;
  }

  return submitData;
};

export default function AddEditUserModal({
  initialValues,
  onSuccess,
  ...props
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const createUserMutation = useCreateUser();
  const updateUserMutation = useUpdateUser(initialValues?.id);

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  const mutation = useMemo(
    () => (initialValues ? updateUserMutation : createUserMutation),
    [initialValues, updateUserMutation, createUserMutation],
  );

  const handleSubmit = (values) => {
    const data = formatSubmitData(values);

    mutation.mutate(data, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.${initialValues ? 'updateSuccess' : 'createSuccess'}`,
          }),
        );
        onSuccess();
      },
      onError: (error) => {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `common.modal.${initialValues ? 'editTitle' : 'addNewTitle'}`,
      })}
      onOk={() => {
        form
          .validateFields()
          .then(handleSubmit)
          .catch(() => {});
      }}
      okButtonProps={{ loading: mutation.isLoading }}
      {...props}
    >
      <AddEditUserForm
        form={form}
        initialValues={formattedInitialValues(initialValues)}
      />
    </Modal>
  );
}
