import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Row, Col } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
} from 'styles/overrides';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { StyledCard } from 'styles/overrides';

import { VNMobilePhonePattern } from 'utils/validationUtils';

import DetailOrderItem from './DetailOrderItem';

export const getInitialValuesStoreForm = (data) => {
  if (!data) return null;
  const { name, phone, address, status } = data;

  return {
    name,
    phone,
    address,
    status: status?.key,
  };
};

export default function StoreForm({ initialValues, onSubmit = () => {} }) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleValuesChange = (changedValues) => {};

  const handleFinish = (values) => {
    console.log(values);
    // onSubmit(values);
  };
  return (
    <StyledForm
      layout="vertical"
      onValuesChange={handleValuesChange}
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
    >
      <Row gutter={[24, 24]}>
        {/* IMAGES */}
        {/* <Col span={24}>
          <StyledFormItem name="images" rules={[{ required: true }]}>
            <StyledCard
              title={intl.formatMessage({ id: 'views.plans.form.label.image' })}
              bordered={false}
              bodyStyle={{ marginTop: 4 }}
              size="small"
            >
              <PreviewImages
                images={initialValues.images}
                planId={initialValues.plan_id}
                isLoading={false}
              />
            </StyledCard>
          </StyledFormItem>
        </Col> */}

        <Col span={12}>
          <StyledCard
            title={intl.formatMessage({
              id: 'views.plans.form.label.product',
            })}
            bordered={false}
            bodyStyle={{ marginTop: 2 }}
            size="small"
          >
            <DetailOrderItem
              formName="products"
              selectorType="sell"
              initialValues={initialValues.order_detail.sells}
            />
          </StyledCard>
        </Col>

        <Col span={12}>
          <StyledCard
            title={intl.formatMessage({ id: 'views.plans.form.label.gift' })}
            bordered={false}
            bodyStyle={{ marginTop: 2 }}
            size="small"
          >
            <DetailOrderItem
              formName="gifts"
              selectorType="gift"
              initialValues={initialValues.order_detail.gifts}
            />
          </StyledCard>
        </Col>
        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.plans.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.plans.form.label.phone' })}
            name="phone"
            rules={[
              { required: false },
              {
                pattern: VNMobilePhonePattern,
                message: intl.formatMessage({
                  id: 'validate.invalidPhoneNumber',
                }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.plans.form.label.address',
            })}
            name="address"
            rules={[{ required: false }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.plans.form.label.note',
            })}
            name="note"
            rules={[{ required: false }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="danger">
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </Col>
        </Permission>
      </Row>
    </StyledForm>
  );
}
