import { Col, Row } from 'antd';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import ExportFilterSearch from '../ExportFilterSearch/ExportFilterSearch';
import {
  useGetReport1,
  useGetReport2,
  useGetReportQC,
  useGetReportStores,
} from 'api/exportApi';
import ExportItem from '../ExportItem/ExportItem';

export default function ExportBlock() {
  const [search, setSearch] = useCustomSearchParams();

  const report1Mutation = useGetReport1(search);
  const report2Mutation = useGetReport2(search);
  const reportStoresMutation = useGetReportStores(search);
  const reportQCMutation = useGetReportQC(search);

  return (
    <div>
      <ExportFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <Row gutter={[24, 24]}>
        <Col span={8}>
          <ExportItem exportName="store" mutation={reportStoresMutation} />
        </Col>

        <Col span={8}>
          <ExportItem exportName="report1" mutation={report1Mutation} />
        </Col>

        <Col span={8}>
          <ExportItem exportName="report2" mutation={report2Mutation} />
        </Col>

        <Col span={8}>
          <ExportItem
            exportName="reportQC"
            mutation={reportQCMutation}
            isCheckPermission={false}
          />
        </Col>
      </Row>
    </div>
  );
}
