import { useIntl } from 'react-intl';
import { Form, DatePicker } from 'antd';
import { DATE_FORMAT, MONTH_FORMAT } from 'constants/common';
import { FilterForm } from 'components/form';
import { StyledSpace, StyledFormItem, StyledButton } from 'styles/overrides';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { useGetExportImplementationProgress } from 'api/exportApi';
import { Notification } from 'components/Notification';

export default function PerformanceProgressFilterSearch({
  onSubmit,
  initialValues,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const getExportImplementationProgressMutation =
    useGetExportImplementationProgress(initialValues);

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
      }),
    );
  };

  const handleExport = () => {
    getExportImplementationProgressMutation.mutate(
      {},
      {
        onSuccess(response) {
          if (response?.path) {
            window.location.href = response.path;
          }
        },
        onError(error) {
          Notification(
            'error',
            error.message ||
              intl.formatMessage({
                id: 'message.commonError',
              }),
          );
        },
      },
    );
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      formInstance={form}
      extraActions={
        <Permission roles={[ROLE.PL]}>
          <StyledButton
            type="primary"
            onClick={handleExport}
            loading={getExportImplementationProgressMutation.isLoading}
          >
            {intl.formatMessage({ id: 'common.btn.export' })}
          </StyledButton>
        </Permission>
      }
    >
      <StyledSpace size={16}>
        <StyledFormItem name="month">
          <DatePicker
            picker="month"
            format={MONTH_FORMAT}
            placeholder={intl.formatMessage({ id: 'common.placeholder.month' })}
            allowClear
          />
        </StyledFormItem>

        <StyledFormItem name="date">
          <DatePicker
            format={DATE_FORMAT}
            placeholder={intl.formatMessage({ id: 'common.placeholder.date' })}
            allowClear
          />
        </StyledFormItem>
      </StyledSpace>
    </FilterForm>
  );
}
