import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Switch } from 'antd';
import { VNMobilePhonePattern } from 'utils/validationUtils';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputPassword,
} from 'styles/overrides';
import RoleSelector from 'components/selectors/RoleSelector';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import {
  DistrictSelector,
  ProvinceSelector,
  UserSelector,
  WardSelector,
} from 'components/selectors';

export default function AddEditUserForm({ form, initialValues }) {
  const intl = useIntl();
  const isEdit = !!initialValues;

  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (changedValues['district_id']) {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  const handleClear = (name) => {
    if (name === 'province_id') {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (name === 'district_id') {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);

  return (
    <StyledForm
      layout="vertical"
      form={form}
      onValuesChange={handleValuesChange}
      preserve={false}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.fullName',
            })}
            name="full_name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.name',
            })}
            name="user_name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.email',
            })}
            name="email"
            rules={[
              {
                type: 'email',
                message: intl.formatMessage({ id: 'validate.invalidEmail' }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.phone',
            })}
            name="phone"
            rules={[
              {
                pattern: VNMobilePhonePattern,
                message: intl.formatMessage({
                  id: 'validate.invalidPhoneNumber',
                }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            name="province_id"
            label={intl.formatMessage({
              id: 'views.users.form.label.provinceId',
            })}
          >
            <ProvinceSelector
              allowClear
              onClear={() => handleClear('province_id')}
              placeholder=""
            />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');

              return (
                <StyledFormItem
                  name="district_id"
                  label={intl.formatMessage({
                    id: 'views.users.form.label.districtId',
                  })}
                >
                  <DistrictSelector
                    provinceId={provinceId}
                    allowClear
                    onClear={() => handleClear('district_id')}
                    placeholder=""
                  />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id ||
              prevValues.district_id !== currentValues.district_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');
              const districtId = getFieldValue('district_id');

              return (
                <StyledFormItem
                  name="ward_id"
                  label={intl.formatMessage({
                    id: 'views.users.form.label.wardId',
                  })}
                >
                  <WardSelector
                    provinceId={provinceId}
                    districtId={districtId}
                    allowClear
                    placeholder=""
                  />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.password',
            })}
            name="password"
            rules={[{ required: true }]}
          >
            <StyledInputPassword />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.role',
            })}
            name="role"
            rules={[{ required: true }]}
          >
            <RoleSelector disabled={isEdit} />
          </StyledFormItem>
        </Col>

        <StyledFormItem
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.role !== currentValues.role
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('role') === ROLE.USER ? (
              <Col span={24} sm={12}>
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.users.form.label.sup',
                  })}
                  name="sup_id"
                  rules={[{ required: false }]}
                >
                  <UserSelector
                    allowClear
                    params={{ role: ROLE.SUP, page_size: 1000 }}
                    placeholder=""
                  />
                </StyledFormItem>
              </Col>
            ) : null
          }
        </StyledFormItem>

        {isEdit && (
          <Permission roles={[ROLE.ADMIN]}>
            <Col span={24} sm={12}>
              <StyledFormItem
                label={intl.formatMessage({
                  id: 'views.users.form.label.active',
                })}
                name="is_active"
                rules={[{ required: true }]}
                valuePropName="checked"
              >
                <Switch />
              </StyledFormItem>
            </Col>
          </Permission>
        )}
      </Row>
    </StyledForm>
  );
}
