import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useStore, useUpdateStore, useSetStoreAvatar } from 'api/storeApi';
import StoreForm, { getInitialValuesStoreForm } from './components/StoreForm';
import { Spin, Avatar, Modal } from 'antd';
import { Notification } from 'components/Notification';
import { useRotateFile, useDeleteFile } from 'api/fileApi';
import { StyledSpace } from 'styles/overrides';
import { PreviewImages } from 'components/common';
import { StoreUploadImage } from './components';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';

export default function ViewStore() {
  const intl = useIntl();
  const { id } = useParams();
  const updateStoreMutation = useUpdateStore(id);
  const rotateMutation = useRotateFile();
  const deleteImageMutation = useDeleteFile();
  const setStoreAvatarMutation = useSetStoreAvatar(id);

  const { data, isLoading, refetch, isFetching } = useStore({
    id,
    options: {
      select: (response) => response?.data ?? null,
    },
  });

  const handleUpdate = (values) => {
    updateStoreMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({
            id: 'message.updateSuccess',
          }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message ||
            intl.formatMessage({
              id: 'message.commonError',
            }),
        );
      },
    });
  };

  const handleRotateImage = (id, rotate) => {
    rotateMutation.mutate(
      { id, rotate: -rotate },
      {
        onSuccess: () => {
          refetch();
        },
        onError: (error) => {
          Notification(
            'error',
            error.message ||
              intl.formatMessage({
                id: 'message.commonError',
              }),
          );
        },
      },
    );
  };

  const handleDeleteImage = useCallback((imageId) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteImageMutation.mutateAsync(
          { id: imageId, params: { store_id: id } },
          {
            onSuccess() {
              Notification(
                'success',
                intl.formatMessage({
                  id: 'message.deleteSuccess',
                }),
              );

              refetch();
            },
            onError(error) {
              Notification(
                'error',
                error.message ||
                  intl.formatMessage({
                    id: 'message.commonError',
                  }),
              );
            },
          },
        );
      },
      onCancel() {},
    });
  }, []);

  const handleSetAvatarImage = useCallback((id) => {
    const data = {
      image_id: id,
    };

    setStoreAvatarMutation.mutate(data, {
      onSuccess: () => {
        refetch();
      },
      onError: (error) => {
        Notification(
          'error',
          error.message ||
            intl.formatMessage({
              id: 'message.commonError',
            }),
        );
      },
    });
  }, []);

  return (
    <Spin
      spinning={
        updateStoreMutation.isLoading ||
        isLoading ||
        rotateMutation.isLoading ||
        setStoreAvatarMutation.isLoading ||
        isFetching
      }
    >
      {!isLoading && (
        <StyledSpace direction="vertical" size={24}>
          {data.images?.length > 0 && (
            <PreviewImages
              images={data.images}
              onRotateImage={handleRotateImage}
              onDeleteImage={handleDeleteImage}
              canDelete
              canSetAvatar
              onSetAvatar={handleSetAvatarImage}
            />
          )}

          <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
            <StoreUploadImage storeId={id} onSuccess={refetch} />
          </Permission>

          {data.image && (
            <div style={{ textAlign: 'center' }}>
              <Avatar size={120} src={data.image.path} shape="square" />
            </div>
          )}

          <StoreForm
            initialValues={getInitialValuesStoreForm(data)}
            onSubmit={handleUpdate}
          />
        </StyledSpace>
      )}
    </Spin>
  );
}
