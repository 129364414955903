import { useMemo, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useGetMapData, useGetTracking } from 'api/dashboardApi';
import { useGeometryMultiPolygon } from 'api/publicApi';
import MapFilterSearch from '../MapFilterSearch/MapFilterSearch';
import MapBlock from '../MapBlock/MapBlock';
import MapInfo from '../MapInfo/MapInfo';

function getPolygons(polygon) {
  let polygons = [];

  if (!polygon) {
    return polygons;
  }

  const { geometry_multipolygon, childrens } = polygon;

  if (geometry_multipolygon) {
    polygons.push(geometry_multipolygon);
  }

  if (childrens?.length > 0) {
    childrens.forEach((item) => {
      const polygonItems = getPolygons(item);

      polygons = [...polygons, ...polygonItems];
    });
  }

  return polygons;
}

export default function Map() {
  const [search, setSearch] = useCustomSearchParams();
  const [infos, setInfos] = useState([]);
  const { data = [], isLoading } = useGetMapData({ params: search });

  const { data: trackingUserData = [], isLoading: isLoadingTrackingUserData } =
    useGetTracking({
      params: search,
      options: {
        enabled:
          ((!!search.from_day && !!search.to_day && !!search.user_id) ||
            !!search.district_id) &&
          search.checked_tracking === 'true',
      },
    });

  const { data: polygons = [], isLoading: isLoadingPolygon } =
    useGeometryMultiPolygon({
      params: { district_id: search.district_id, ward_id: search.ward_id },
      options: {
        enabled: !!search.district_id || !!search.ward_id,
        select: (response) => getPolygons(response),
      },
    });

  const markers = useMemo(() => {
    return _.reduce(
      data,
      (acc, currentItem) => {
        const { color, gps } = currentItem;

        acc.push(..._.map(gps, (item) => ({ ...item, color })));

        return acc;
      },
      [],
    );
  }, [data]);

  useEffect(() => {
    if (data.length <= 0) return;

    setInfos(_.map(data, ({ name, color }) => ({ name, color })));
  }, [data]);

  console.log({ polygons });

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24} sm={8}>
          <MapFilterSearch
            onSubmit={(values) => setSearch(values)}
            initialValues={search}
          />

          <MapInfo infos={infos} />
        </Col>

        <Col span={24} sm={16}>
          <MapBlock
            isLoading={
              isLoading || isLoadingTrackingUserData || isLoadingPolygon
            }
            markers={markers}
            trackings={trackingUserData}
            polygons={polygons}
          />
        </Col>
      </Row>
    </>
  );
}
