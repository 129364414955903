import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import {
  calcCurrentPageWithNum,
  calcIndexByOrder,
  removeFalsyKeys,
} from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { StyledActions, StyledButton } from 'styles/overrides';
import { Table } from 'components/table';
import {
  useUsers,
  useDeleteUser,
  useDownloadTemplateImport,
  useImportUsers,
} from 'api/userApi';
import { Notification } from 'components/Notification';
import { formatTime } from 'utils/timeUtils';
import AddEditUserModal from '../AddEditUserModal/AddEditUserModal';
import UserFilterSearch from '../UserFilterSearch/UserFilterSearch';
import { Upload } from 'components/upload';
import { DownloadTemplate } from 'components/download-template/DownloadTemplate';
import { Typography } from 'antd';

export default function UserTable() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, refetch } = useUsers({ params: search });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const deleteUserMutation = useDeleteUser();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const downloadTemplateImportMutation = useDownloadTemplateImport();
  const importMutation = useImportUsers();

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.page_size,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.fullName' }),
      dataIndex: 'full_name',
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.name' }),
      render: ({ user_name, is_active }) => (
        <Typography.Text type={!!is_active ? undefined : 'danger'}>
          {user_name}
        </Typography.Text>
      ),
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.password' }),
      dataIndex: 'password_raw',
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.email' }),
      dataIndex: 'email',
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.phone' }),
      dataIndex: 'phone',
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.role' }),
      dataIndex: ['role', 'name'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
  ];

  const handleDelete = async () => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteUserMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            const calculatedPage = calcCurrentPageWithNum({
              page: search.page,
              limit: search.page_size,
              total: data?.meta?.total,
              num: selectedRowKeys.length,
            });

            if (calculatedPage !== search.page) {
              setSearch(
                removeFalsyKeys({
                  ...search,
                  page: calculatedPage,
                }),
              );
            } else {
              refetch();
            }

            setSelectedValue(null);
            setSelectedRows([]);
            setSelectedRowKeys([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  const handleClickEdit = () => {
    if (selectedRows.length !== 1) {
      return;
    }

    setIsOpenModal(true);
    setSelectedValue(selectedRows[0]);
  };

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, page_size: limit }));
  };

  return (
    <>
      <UserFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <StyledActions>
        <StyledButton type="primary" onClick={() => setIsOpenModal(true)}>
          {intl.formatMessage({ id: 'common.btn.addNew' })}
        </StyledButton>

        <StyledButton
          disabled={selectedRows.length !== 1}
          onClick={handleClickEdit}
        >
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>

        <Upload mutation={importMutation} onSuccess={refetch} />

        <DownloadTemplate mutation={downloadTemplateImportMutation} />

        <StyledButton
          type="danger"
          onClick={handleDelete}
          disabled={selectedRowKeys.length <= 0}
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data?.data || []}
        loading={isLoading || deleteUserMutation.isLoading}
        pagination={{
          current: search?.page,
          pageSize: search?.page_size,
          total: data?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />

      <AddEditUserModal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
          setSelectedValue(null);
        }}
        onSuccess={() => {
          setIsOpenModal(false);
          refetch();
          setSelectedValue(null);
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }}
        initialValues={selectedValue}
      />
    </>
  );
}
