import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Spin, Divider, Typography, List } from 'antd';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useMilkGroupChart, usePosmChart } from 'api/dashboardApi';
import ResultChartFilterSearch from '../ResultChartFilterSearch/ResultChartFilterSearch';
import { StyledSpace } from '../../../../../styles/overrides';
import DonutChart from '../../DonutChart/DonutChart';
import ColumnChart from '../../ColumnChart/ColumnChart';
import BarChart from '../../BarChart/BarChart';

export default function ResultChart() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, isFetching } = useMilkGroupChart({ params: search });
  const {
    data: posm,
    isLoading: isLoadingPosm,
    isFetching: isFetchingPosm,
  } = usePosmChart({ params: search });

  return (
    <>
      <ResultChartFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <Spin
        spinning={isLoading || isFetching || isLoadingPosm || isFetchingPosm}
      >
        <StyledSpace
          direction="vertical"
          split={
            <Divider
              type="horizontal"
              style={{ borderWidth: 2, borderColor: 'var(--black)' }}
            />
          }
        >
          {_.map(data, (value, key) => {
            return (
              <div key={key}>
                <Typography.Title level={3} type="danger">
                  {intl.formatMessage({
                    id: `views.dashboard.resultChart.title.${key}`,
                  })}
                </Typography.Title>

                <List
                  grid={{
                    gutter: 16,
                    column: value.length,
                  }}
                  dataSource={value}
                  renderItem={(item) => (
                    <List.Item>
                      <DonutChart item={item} />
                    </List.Item>
                  )}
                />
              </div>
            );
          })}

          {_.map(posm, (value, key) => {
            return (
              <div key={key}>
                <Typography.Title level={3} type="danger">
                  {intl.formatMessage({
                    id: `views.dashboard.resultChart.title.${key}`,
                  })}
                </Typography.Title>

                {key === 'posm' ? (
                  <ColumnChart data={value.details} total={value.total_value} />
                ) : (
                  <BarChart data={value.details} total={value.total_value} />
                )}
              </div>
            );
          })}
        </StyledSpace>
      </Spin>
    </>
  );
}
