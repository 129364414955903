import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Row, Col, Spin } from 'antd';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useProgressChart } from 'api/dashboardApi';
import ProgressChartFilterSearch from '../ProgressChartFilterSearch/ProgressChartFilterSearch';
import DonutChart from '../../DonutChart/DonutChart';

export default function ProgressChart() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, isFetching } = useProgressChart({ params: search });

  return (
    <>
      <ProgressChartFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <Spin spinning={isLoading || isFetching}>
        <Row gutter={[24, 24]}>
          {_.map(data, (value, key) => (
            <Col span={24} sm={8} key={key}>
              <DonutChart
                item={{
                  ...value,
                  name: intl.formatMessage({
                    id: `views.dashboard.progressChart.title.${key}`,
                  }),
                }}
              />
            </Col>
          ))}
        </Row>
      </Spin>
    </>
  );
}
