import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Modal, Spin } from 'antd';
import { StyledCard, StyledButton } from 'styles/overrides';
import PlanUploadImageModal from '../PlanUploadImageModal/PlanUploadImageModal';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { PreviewImages } from 'components/common';
import { Notification } from 'components/Notification';
import { FILE_GROUP_TYPE } from 'constants/common';
import { useFileGroups, useDeleteFile } from 'api/fileApi';
import { useGetPlanFiles } from 'api/planApi';

export default function PlanImages({ planId }) {
  const intl = useIntl();
  const { data: fileGroups, isLoading: isLoadingFileGroups } = useFileGroups({
    params: { type: FILE_GROUP_TYPE.PLAN },
  });
  const {
    data: files,
    isLoading: isLoadingFiles,
    isFetching: isFetchingFiles,
    refetch: refetchFiles,
  } = useGetPlanFiles({
    planId,
  });
  const deleteFileMutation = useDeleteFile();
  const [isOpen, setIsOpen] = useState(false);

  const convertedFileGroups = useMemo(() => {
    const groupedFile = _.reduce(
      files,
      (result, item) => {
        const { id, path } = item;

        (result[item.group.id] || (result[item.group.id] = [])).push({
          id,
          path,
        });

        return result;
      },
      {},
    );

    return _.map(fileGroups, (item) => ({
      ...item,
      images: groupedFile[item.id] ?? [],
    }));
  }, [files, fileGroups]);

  const handleToggleModal = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleSuccess = () => {
    handleToggleModal();
    refetchFiles();
  };

  const handleDeleteImage = (imageId) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteFileMutation.mutateAsync(
          { id: imageId },
          {
            onSuccess() {
              Notification(
                'success',
                intl.formatMessage({
                  id: 'message.deleteSuccess',
                }),
              );

              refetchFiles();
            },
            onError(error) {
              Notification(
                'error',
                error.message ||
                  intl.formatMessage({
                    id: 'message.commonError',
                  }),
              );
            },
          },
        );
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Spin spinning={isLoadingFileGroups || isLoadingFiles || isFetchingFiles}>
        <StyledCard
          title={intl.formatMessage({ id: 'views.plans.form.label.image' })}
          bordered={false}
          bodyStyle={{ padding: 0 }}
          size="small"
          extra={
            <Permission roles={[ROLE.ADMIN]}>
              <StyledButton onClick={handleToggleModal} type="danger">
                {intl.formatMessage({ id: 'common.btn.addImages' })}
              </StyledButton>
            </Permission>
          }
        >
          {_.map(convertedFileGroups, ({ id, name, images }) => {
            return (
              <StyledCard size="small" title={name} key={id}>
                <PreviewImages
                  images={images}
                  canDelete={true}
                  onDeleteImage={handleDeleteImage}
                />
              </StyledCard>
            );
          })}
        </StyledCard>
      </Spin>

      <PlanUploadImageModal
        planId={planId}
        open={isOpen}
        onCancel={handleToggleModal}
        onSuccess={handleSuccess}
      />
    </>
  );
}
