import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Avatar, Typography, Modal } from 'antd';
import {
  calcCurrentPageWithNum,
  calcIndexByOrder,
  removeFalsyKeys,
} from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { Table } from 'components/table';
import { formatTime } from 'utils/timeUtils';
import { useStores, useDeleteMultipleStores } from 'api/storeApi';
import { StyledActions, StyledButton } from 'styles/overrides';
import StoreFilterSearch from '../StoreFilterSearch/StoreFilterSearch';
import { formatAddress } from 'utils/formatUtils';
import { Notification } from 'components/Notification';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';

export default function StoreTable() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, refetch } = useStores({ params: search });
  const deleteMultipleStoresMutation = useDeleteMultipleStores();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.page_size,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.stores.table.storeAvatar' }),
      dataIndex: ['image', 'path'],
      render: (value) => <Avatar src={value} size={90} shape="square" />,
    },
    {
      title: 'Tên cửa hàng/Mã cửa hàng/Số điện thoại',
      render: ({ name, code, phone }) => (
        <div>
          <Typography.Title level={5}>{name}</Typography.Title>
          <Typography.Paragraph type="danger" style={{ marginBottom: 0 }}>
            {code}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ marginBottom: 0 }}>
            {phone}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: 'Địa chỉ',
      render: ({ address, street, ward, district, province }) =>
        formatAddress({
          address,
          street,
          ward: ward?.name,
          district: district?.name,
          province: province?.name,
        }),
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
    {
      dataIndex: 'id',
      render: (id) => (
        <Link to={`${id}`}>
          <StyledButton type="primary">Chi tiết</StyledButton>
        </Link>
      ),
    },
  ];

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, page_size: limit }));
  };

  const handleDelete = () => {
    if (selectedRowKeys.length <= 0) return;

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMultipleStoresMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            const calculatedPage = calcCurrentPageWithNum({
              page: search.page,
              limit: search.page_size,
              total: data?.meta?.total,
              num: selectedRowKeys.length,
            });

            if (calculatedPage !== search.page) {
              setSearch(
                removeFalsyKeys({
                  ...search,
                  page: calculatedPage,
                }),
              );
            } else {
              refetch();
            }

            setSelectedRowKeys([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <StoreFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />
      <StyledActions size={24}>
        <StyledButton
          type="primary"
        >
          <Link
            to={'/stores/create'}
          >
            {intl.formatMessage({ id: 'common.btn.addNew' })}
          </Link>
        </StyledButton>

        {/* <StyledButton
          size="large"
          disabled={selectedRowKeys.length === 0}
          onClick={handleDelete}
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton> */}
      </StyledActions>
      <Permission roles={[ROLE.PL]}>
        <StyledActions size={24}>
          <StyledButton
            onClick={handleDelete}
            type="danger"
            disabled={selectedRowKeys.length <= 0}
          >
            {intl.formatMessage({ id: 'common.btn.delete' })}
          </StyledButton>
        </StyledActions>
      </Permission>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data?.data || []}
        loading={isLoading}
        pagination={{
          current: search?.page,
          pageSize: search?.page_size,
          total: data?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />
    </>
  );
}
