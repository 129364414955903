import { useIntl } from 'react-intl';
import { Form, Row, Col, Space, Input, Button } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledFormList,
} from 'styles/overrides';
import { VNMobilePhonePattern } from 'utils/validationUtils';
import PlanProductsFormList from '../PlanProductsFormList/PlanProductsFormList';
import { MinusCircleOutlined } from '@ant-design/icons';
import { PRODUCT_TYPE } from 'constants/common';

export default function PlanOrderForm({ initialValues }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  return (
    <StyledForm
      layout="vertical"
      size="large"
      form={form}
      initialValues={initialValues}
    >
      <Row gutter={[24, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            name="name"
            label={intl.formatMessage({
              id: 'views.plans.planOrders.form.label.name',
            })}
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            name="phone"
            label={intl.formatMessage({
              id: 'views.plans.planOrders.form.label.phone',
            })}
            rules={[
              { required: true },
              {
                pattern: VNMobilePhonePattern,
                message: intl.formatMessage({
                  id: 'validate.invalidPhoneNumber',
                }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            name="address"
            label={intl.formatMessage({
              id: 'views.plans.planOrders.form.label.address',
            })}
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            name="note"
            label={intl.formatMessage({
              id: 'views.plans.planOrders.form.label.note',
            })}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <PlanProductsFormList
            name="products"
            productType={PRODUCT_TYPE.SELL}
          />
        </Col>

        <Col span={24}>
          {/* <StyledFormList name="gifts"></StyledFormList> */}
        </Col>
      </Row>
    </StyledForm>
  );
}
