export const ROLE = {
  ADMIN: 'admin',
  USER: 'user',
  SUP: 'sup',

  ROOT: 'root',
  PL: 'pl',
  QC: 'qc',
  CUSTOMER: 'customer',
};

export const ROLES = ['supper_admin', 'admin', 'viewer'];
