import { useGetDetailOrder } from 'api/planApi';
import React from 'react';
import { useParams } from 'react-router-dom';
import DetailOrderForm from '../DetailOrderForm/DetailOrderForm';
function DetailOrder() {
  const { id } = useParams();
  let params = window.location.pathname.split('/').slice(-2);
  const planId = params.shift();
  const {
    data: detailOrder,
    isLoading,
    isSuccess,
  } = useGetDetailOrder({ planId, id });
  if (!isSuccess) return <div>Loading...</div>;


  return <DetailOrderForm initialValues={detailOrder.data} />;
}

export default DetailOrder;
