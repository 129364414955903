import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import {
  StyledFormItem,
  StyledInput,
  StyledInputNumber,
  StyledSpace,
  StyledButton,
  StyledFormList,
} from 'styles/overrides';

export default function PlanProductsFormList({ name, productType }) {
  const intl = useIntl();

  return (
    <StyledFormItem
      label={intl.formatMessage({ id: `common.productType.${productType}` })}
    >
      <StyledFormList name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} gutter={[24, 0]}>
                <Col span={10}>
                  <StyledFormItem
                    {...restField}
                    name={[name, 'id']}
                    rules={[{ required: true }]}
                    label="Tên sản phẩm"
                  >
                    <StyledInput />
                  </StyledFormItem>
                </Col>

                <Col span={10}>
                  <StyledFormItem
                    {...restField}
                    name={[name, 'quantity']}
                    rules={[{ required: true }]}
                    label="Số lượng"
                  >
                    <StyledInputNumber min={0} controls={false} />
                  </StyledFormItem>
                </Col>

                {/* <Col span={4}>
                  <StyledButton type="danger">
                    {intl.formatMessage({ id: 'common.btn.delete' })}
                  </StyledButton>
                </Col> */}
              </Row>
            ))}

            <StyledFormItem style={{ marginBottom: 0, textAlign: 'center' }}>
              <StyledButton type="primary" onClick={() => add()}>
                {intl.formatMessage({ id: 'common.btn.addNew' })}
              </StyledButton>
            </StyledFormItem>
          </>
        )}
      </StyledFormList>
    </StyledFormItem>
  );
}
