import { useMemo } from 'react';
import { Bar } from '@ant-design/plots';
import _ from 'lodash';

export default function BarChart({ total, data = [] }) {
  const config = useMemo(() => {
    return {
      data,
      xField: 'value',
      yField: 'name',
      seriesField: 'name',
      color: _.map(data, (item) => item.color),
      xAxis: {
        label: {
          autoHide: false,
          autoRotate: false,
        },
      },
      minColumnWidth: 20,
      maxColumnWidth: 20,
      legend: false,
      annotations: _.map(data, (item) => ({
        type: 'text',
        content: `${item.value}`,
        style: { textAlign: 'center', fontSize: 14, fill: 'var(--black)' },
        offsetX: 15,
        position: [item.name, item.value],
      })),
    };
  }, [data]);

  return <Bar {...config} />;
}
