import React, {useState} from 'react';
import { useIntl } from 'react-intl';
import { Form, Row, Col } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
} from 'styles/overrides';
import {
  ProvinceSelector,
  DistrictSelector,
  WardSelector,
} from 'components/selectors';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import MapModal from 'views/dashboard/components/map/MapModal'
import { VNMobilePhonePattern } from 'utils/validationUtils';

export const getInitialValuesStoreForm = (data) => {
  if (!data) return null;
  const {
    name,
    code,
    phone,
    province,
    district,
    ward,
    address,
    status,
    lat,
    long
  } = data;

  return {
    code,
    name,
    phone,
    province_id: province?.id?.toString(),
    district_id: district?.id?.toString(),
    ward_id: ward?.id?.toString(),
    address,
    status: status?.key,
    lat,
    lng: long
  };
};

export default function StoreForm({ initialValues, onSubmit = () => {} }) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [coordinate, setCoordinate] = useState(null)

  const handleGetPlaceFromMap = (place) => {
    setCoordinate(place)
    form.setFieldsValue(place) // set the lat lng to input field
  }
  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (changedValues['district_id']) {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  const handleFinish = (values) => {
    onSubmit(values);
  };

  return (
    <StyledForm
      layout="vertical"
      onValuesChange={handleValuesChange}
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
    >
      <Row gutter={[24, 24]}>
        {initialValues ? (
          <Col span={8}>
            <StyledFormItem
              label={intl.formatMessage({
                id: 'views.stores.form.label.storeCode',
              })}
              name="code"
            >
              <StyledInput disabled />
            </StyledFormItem>
          </Col>
        ) : null}

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.storeName',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.phone' })}
            name="phone"
            rules={[
              { required: false },
              {
                pattern: VNMobilePhonePattern,
                message: intl.formatMessage({
                  id: 'validate.invalidPhoneNumber',
                }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.provinceId',
            })}
            name="province_id"
            rules={[{ required: true }]}
          >
            <ProvinceSelector />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.stores.form.label.districtId',
                  })}
                  name="district_id"
                  rules={[{ required: true }]}
                >
                  <DistrictSelector provinceId={provinceId} />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id ||
              prevValues.district_id !== currentValues.district_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');
              const districtId = getFieldValue('district_id');

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.stores.form.label.wardId',
                  })}
                  name="ward_id"
                  rules={[{ required: true }]}
                >
                  <WardSelector
                    provinceId={provinceId}
                    districtId={districtId}
                  />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.address',
            })}
            name="address"
            rules={[{ required: false }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.lat' })}
            name="lat"
            rules={[{ required: true }]}
          >
            <StyledInput value={coordinate?.lat}  disabled/>
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.long' })}
            name="lng"
            rules={[{ required: true }]}
          >
            <StyledInput value={coordinate?.lng} disabled />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
              label={intl.formatMessage({ id: 'views.stores.form.label.guideOpenMap' })}
            >
              
            <MapModal isModalOpen={isModalOpen} onCancel={() => setIsModalOpen(false)} getCoordinateFromMap={handleGetPlaceFromMap}/>

            <StyledButton type="primary" onClick={() => setIsModalOpen(true)}>
              {intl.formatMessage({ id: 'common.btn.openMap' })}
            </StyledButton>
          </StyledFormItem>

        </Col>

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="danger">
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </Col>
        </Permission>
      </Row>
    </StyledForm>
  );
}
