/* eslint-disable no-unused-vars */
import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import {
  calcIndexByOrder,
  removeFalsyKeys,
  calcCurrentPageWithNum,
  formatCurrency,
} from 'utils';
import { Table } from 'components/table';
import { StyledActions, StyledButton } from 'styles/overrides';
import {
  useDeleteProduct,
  useProducts,
} from 'api/productApi';
import { Notification } from 'components/Notification';
import { ProductFilterSearchForm } from './ProductFilterSearchForm';
import { ProductModal } from './ProductModal';
import { useGetAllTypes } from 'api/productApi';
import { intersection } from 'lodash';
import { Modal } from 'antd';
export const ProductTable = () => {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data: product, isLoading, refetch } = useProducts({ params: search });
  const { data, isSuccess } =  useGetAllTypes()
  const deleteProductMutation = useDeleteProduct();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  if(!isSuccess) return
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      dataIndex: 'id',
      // render: (text, record, index) => {
      //   return calcIndexByOrder({
      //     index,
      //     page: search.page,
      //     limit: search.limit,
      //   });
      // },
    },
    {
      title: intl.formatMessage({ id: 'views.products.table.code' }),
      dataIndex: 'code',
      className: 'withColor',
    },
    {
      title: intl.formatMessage({ id: 'views.products.table.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'views.products.table.quantity' }),
      dataIndex: 'quantity',
    },
    {
      title: intl.formatMessage({ id: 'views.products.table.price' }),
      dataIndex: 'price',
      render: (value) => formatCurrency(value),
    },
    {
      title: intl.formatMessage({ id: 'views.products.table.type' }),
      dataIndex: 'type',
      render: (values) => {
        const intersection = data.data.filter(item => {
            return values.includes(item.code)
          })
        return intersection.map(item => item.name + ", ")
      },
    },
    {
      title: intl.formatMessage({ id: 'views.products.table.createdAt' }),
      dataIndex: 'created_at',
    },
  ];

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, limit }));
  };

  const handleDelete = async () => {
      Modal.confirm({
        title: intl.formatMessage({id: 'common.modal.deleteTitle'}),
        okText: intl.formatMessage({id: 'common.btn.confirm'}),
        onOk(){
          return deleteProductMutation.mutateAsync(selectedRowKeys, {
            onSuccess(){
              const calculatedPage = calcCurrentPageWithNum({
                page: search.page,
                limit: search.limit,
                total: product.items,
                num: selectedRowKeys.length,
              });

              if (calculatedPage !== search.page) {
                setSearch(
                  removeFalsyKeys({
                    ...search,
                    page: calculatedPage,
                  }),
                );
              } else {
                refetch();
              }
              Notification('success',  
                intl.formatMessage({ id: 'message.deleteSuccess' })
              )
              setSelectedRowKeys([]);
              setSelectedRows([]);
            },
            onError(error){
              Notification('error', error?.message || 
                intl.formatMessage({ id: 'message.commonError' })
              )
            }
          })
        },
        onCancel() {},

      })

  };

  const handleClickEdit = () => {
    if (selectedRows.length !== 1) {
      return;
    }

    setIsOpenModal(true);
    setSelectedValue(selectedRows[0]);
  };

  return (
    <>
      <ProductFilterSearchForm
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <StyledActions size={24}>
        <StyledButton
          size="large"
          type="primary"
          onClick={() => setIsOpenModal(true)}
        >
          {intl.formatMessage({ id: 'common.btn.addNew' })}
        </StyledButton>

        <StyledButton
          size="large"
          disabled={selectedRows.length !== 1}
          onClick={handleClickEdit}
        >
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>

        <StyledButton
          size="large"
          disabled={selectedRowKeys.length === 0}
          onClick={handleDelete}
          type='danger'
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={product?.data || []}
        loading={isLoading}
        pagination={{
          current: search?.page,
          pageSize: search?.page_size,
          total: product?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />

      <ProductModal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
          setSelectedValue(null);
        }}
        onSuccess={() => {
          setIsOpenModal(false);
          refetch();
          setSelectedValue(null);
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }}
        initialValues={selectedValue}
      />
    </>
  );
};
