import _ from 'lodash';
import { Typography } from 'antd';
import styled from 'styled-components';
import { MarkerIcon, FootPrintIcon } from 'assets/icons';
import { StyledSpace } from 'styles/overrides';

const StyledMapInfo = styled(StyledSpace)`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 24px;
  justify-content: flex-end;
`;

export default function MapInfo({ infos = [] }) {
  return (
    <>
      {infos.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          <StyledMapInfo direction="vertical">
            <StyledSpace align="center">
              <FootPrintIcon style={{ color: '#E25B1E', fontSize: 20 }} />
              <Typography.Text style={{ color: '#E25B1E' }}>
                Ping dấu chân
              </Typography.Text>
            </StyledSpace>

            {_.map(infos, ({ color, name }) => (
              <StyledSpace key={name} align="center">
                <MarkerIcon style={{ color, fontSize: 20 }} />
                <Typography.Text style={{ color }}>{name}</Typography.Text>
              </StyledSpace>
            ))}
          </StyledMapInfo>
        </div>
      )}
    </>
  );
}
