import { useIntl } from 'react-intl';
import { DatePicker, Form, Row, Col } from 'antd';
import { MONTH_FORMAT } from 'constants/common';
import { FilterForm } from 'components/form';
import { StyledFormItem } from 'styles/overrides';
import {
  FromToDateSelector,
  ProvinceSelector,
  UserSelector,
  DistrictSelector,
  WardSelector,
} from 'components/selectors';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';
import { ROLE } from 'constants/roles';
import { Checkbox } from 'antd';
import _ from 'lodash';

export default function MapFilterSearch({ onSubmit, initialValues }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit(normalizeSubmitSearchData({ ...values }));
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (changedValues['district_id']) {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  const handleClear = (name) => {
    if (name === 'province_id') {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (name === 'district_id') {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      formInstance={form}
      onValuesChange={handleValuesChange}
      preserve={false}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <StyledFormItem name="province_id">
            <ProvinceSelector
              allowClear
              onClear={() => handleClear('province_id')}
            />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');

              return (
                <StyledFormItem name="district_id">
                  <DistrictSelector
                    provinceId={provinceId}
                    allowClear
                    onClear={() => handleClear('district_id')}
                  />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id ||
              prevValues.district_id !== currentValues.district_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');
              const districtId = getFieldValue('district_id');

              return (
                <StyledFormItem name="ward_id">
                  <WardSelector
                    provinceId={provinceId}
                    districtId={districtId}
                    allowClear
                  />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem name="month">
            <DatePicker
              picker="month"
              format={MONTH_FORMAT}
              placeholder={intl.formatMessage({
                id: 'common.placeholder.month',
              })}
              allowClear
            />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem name="fromToDate">
            <FromToDateSelector allowClear format="DD/MM/YYYY" />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem name="user_id">
            <UserSelector
              allowClear
              params={{ role: ROLE.USER, page_size: 1000 }}
            />
          </StyledFormItem>
        </Col>

        <Col span={24} style={{ marginBottom: 0 }}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.fromToDate !== currentValues.fromToDate ||
              prevValues.user_id !== currentValues.user_id ||
              prevValues.district_id !== currentValues.district_id
            }
          >
            {({ getFieldValue }) => {
              const fromToDate = getFieldValue('fromToDate');
              const userId = getFieldValue('user_id');
              const districtId = getFieldValue('district_id');

              const isValid =
                (!!_.get(fromToDate, ['0']) &&
                  !!_.get(fromToDate, ['1']) &&
                  !!userId) ||
                !!districtId;

              return isValid ? (
                <StyledFormItem name="checked_tracking" valuePropName="checked">
                  <Checkbox>
                    {intl.formatMessage({ id: 'common.btn.tracking' })}
                  </Checkbox>
                </StyledFormItem>
              ) : null;
            }}
          </StyledFormItem>
        </Col>
      </Row>
    </FilterForm>
  );
}
