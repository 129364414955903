import { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Form } from 'antd';
import { Modal } from 'components/modal/Modal';
import { Notification } from 'components/Notification';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import { useCreateFile } from 'api/fileApi';
import PlanUploadImageForm from '../PlanUploadImageForm/PlanUploadImageForm';

export default function PlanUploadImageModal({
  planId,
  onSuccess = () => {},
  ...props
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const createFileMutation = useCreateFile();
  const [isLoading, setIsLoading] = useState(false);

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  const handleSubmit = async ({ files, group_id }) => {
    setIsLoading(true);
    try {
      const filesWithMutation = _.map(files, (file) => {
        const formData = new FormData();

        formData.append('plan_id', planId);
        formData.append('group_id', group_id);
        formData.append('type', 'image');
        formData.append('file', file.originFileObj);

        return createFileMutation.mutateAsync(formData);
      });

      await Promise.all(filesWithMutation);

      Notification(
        'success',
        intl.formatMessage({
          id: 'message.createSuccess',
        }),
      );

      onSuccess();
    } catch (error) {
      Notification(
        'error',
        error.message ?? intl.formatMessage({ id: 'message.commonError' }),
      );
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'common.modal.addNewTitle' })}
      onOk={() => {
        form
          .validateFields()
          .then(handleSubmit)
          .catch(() => {});
      }}
      okButtonProps={{ loading: isLoading }}
      {...props}
    >
      <PlanUploadImageForm form={form} />
    </Modal>
  );
}
